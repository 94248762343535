<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo d-none d-md-block">
        <img src="@/assets/images/logo/sisternet-logo.png" alt="" />
      </b-link>
      <b-link class="brand-logo d-block d-md-none">
        <img
          src="@/assets/images/logo/sisternet-logo-small.svg"
          style="height: 70px"
          alt=""
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Sisternet Dashboard
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="formLogin.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="jane@sisternet.co.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <label for="login-password">Password</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="formLogin.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <p class="text-danger" style="font-size: 12px">{{ messages }}</p>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || isLoading"
                @click="validationForm"
              >
                {{ isLoading ? "Please Wait.." : "Sign In" }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store/index";
import { successNotification, errorNotification } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      status: "",
      formLogin: {
        email: "",
        password: "",
      },
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      messages: "",
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.isLoading = true;
      const isMobile = window.innerWidth < 768;
      this.$http
        .post("/api/v1/admin/auth/login", this.formLogin)
        .then((response) => {
          const { access_token } = response.data.data;
          useJwt.setToken(access_token);
          return this.$http.get("/api/v1/admin/auth/me");
        })
        .then((response) => {
          if (response.data.data) {
            const userData = JSON.stringify(response.data.data);
            const userPermission = JSON.stringify(
              response.data.data.groups.map((group) => group.name)
            );
            localStorage.setItem("userData", userData);
            localStorage.setItem("userPermission", userPermission);
          }
          return Promise.resolve(); // Resolve the promise to continue execution
        })
        .then(() => {
          this.isLoading = false;
          let role = JSON.parse(localStorage.getItem("userPermission"));
          if (
            role.includes("mentorship-mentor") &&
            !role.includes("adminmaster")
          ) {
            localStorage.setItem("userRole", "mentorship");
            this.$router.push({ name: "mentorship-session" });
          } else if (role.includes("adminmaster")) {
            localStorage.setItem("userRole", "adminmaster");
            this.$router.push({ name: "home" });
          } else {
            this.$router.push({ name: "home" });
          }
          successNotification(this, "Success", "Berhasil Login");
          setTimeout(
            () => {
              window.location.reload();
            },
            isMobile ? 2000 : 1000
          );
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.messages) {
            this.messages = error.response.data.meta.messages;
            errorNotification(this, "Ooops!", this.messages);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.brand-logo img {
  // width: 100%;
  height: 100px;
}
</style>
